<div class="monthly-payment">
    <div class="money-container">
        <h3 class="full-label">Est. Monthly Payment</h3>
        <h3 class="short-label">Est. Mo. Pymt.</h3>
        <div class="dollar-amount" (click)="handleMortgageCalculatorModal()">
            <sup>&dollar;</sup>
            <span>{{monthlyPrice}}</span>*
            <icon name="calculator"></icon>
        </div>
    </div>

    <button class="hc-btn primary icon-only calc-btn" (click)="handleMortgageCalculatorModal()">
        <icon name="calculator"></icon>
    </button>

    <div class="mortgage-popup">
        <p *ngIf="mortgageDisclaimer" [innerHtml]="mortgageDisclaimer" class="larger"></p>
        <p *ngIf="!mortgageDisclaimer" class="larger">Based on a {{loanTerms?.Years}} year fixed loan at {{loanTerms?.InterestRate}}% with
            {{loanTerms?.DownPayPercent}}% down.</p>
        <p>To modify these selections, use our mortgage calculator.</p>
        <button (click)="handleMortgageCalculatorModal()" class="hc-btn secondary">Mortgage Calculator</button>
    </div>
</div>

<div class="price-total money-container">
    <h3>
        Home Total
        <icon *ngIf="totalPriceDisclaimer" name="circle_i" [matTooltip]="totalPriceDisclaimer" [matTooltipShowDelay]="0"></icon>
    </h3>
    <div class="dollar-amount">{{totalPrice}}</div>
</div>

<button *ngIf="showReserveBtn" class="hc-btn success reserve-btn">
    Reserve this home
</button>