import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { HcExpandingMenuService } from './hc-expanding-menu.service';

@Component({
  selector: 'hc-expanding-menu',
  templateUrl: './hc-expanding-menu.component.html',
  styleUrls: ['./hc-expanding-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcExpandingMenuComponent
  extends AutoUnsubscriber
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() headerText = 'Main Menu';
  @Input() showBackBtn = false;
  @Output() back = new EventEmitter();
  @Output() homeSelect = new EventEmitter();

  canScrollLeft = false;
  canScrollRight = false;
  private bodyElement: HTMLElement;

  constructor(
    private cdr: ChangeDetectorRef,
    private elemRef: ElementRef<HTMLElement>,
    public menuService: HcExpandingMenuService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSub(
      this.menuService.isExpanded$.subscribe(state => {
        if (state) this.elemRef.nativeElement.classList.add('expanded');
        else this.elemRef.nativeElement.classList.remove('expanded');
      })
    );
  }

  ngAfterViewInit(): void {
    this.bodyElement = this.elemRef.nativeElement.querySelector('.expanding-menu-body');
    if (this.bodyElement) {
      this.bodyElement.addEventListener('scroll', this.checkBodyScroll);

      // Trigger the scroll event to set the initial state
      this.checkBodyScroll();
    }
  }

  ngOnDestroy(): void {
    if (this.bodyElement) this.bodyElement.removeEventListener('scroll', this.checkBodyScroll);
    super.ngOnDestroy();
  }

  setHeaderText(text: string) {
    this.headerText = text;
    this.cdr.markForCheck();
  }

  toggleMenuExpandCollapse() {
    this.menuService.toggleIsExpanded();
    this.checkBodyScroll();
  }

  handleBackButton() {
    this.back.emit();
    this.checkBodyScroll();
  }

  checkBodyScroll = () => {
    if (!this.bodyElement) return;

    window.requestAnimationFrame(() => {
      const element = this.bodyElement;
      this.canScrollLeft = element.scrollLeft > 0;
      this.canScrollRight = element.clientWidth + element.scrollLeft < element.scrollWidth;
      this.cdr.markForCheck();
    });
  };
}
