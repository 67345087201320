<nav>
    <ol>
        <li id="mod-nav-{{mod.Id}}" *ngFor="let mod of modules">
            <div *ngIf="mod.IsDisabled" class="lock-block">
                <icon name="lock"></icon>
            </div>

            <button class="module-btn" (click)="handleModuleSelect(mod)" [class.selected]="mod.IsSelected">
                <div class="top-section">
                    <div class="bubble" [class.empty]="!mod.SelectionHasBeenMade && !mod.SelectionIsRequired && !mod.SelectionIsLocked"
                         [class.warning]="!mod.SelectionHasBeenMade && mod.SelectionIsRequired" [class.locked]="mod.SelectionIsLocked">
                        <icon *ngIf="!mod.SelectionIsLocked && (!mod.SelectionIsRequired || mod.SelectionHasBeenMade)" name="check"></icon>
                        <icon *ngIf="!mod.SelectionIsLocked && mod.SelectionIsRequired && !mod.SelectionHasBeenMade" name="exclamation"></icon>
                        <icon *ngIf="mod.SelectionIsLocked" name="lock"></icon>
                    </div>

                    <p class="subtitle">{{mod.Subtitle}}</p>
                </div>
                <div class="bottom-section">
                    <h3>{{mod.Title}}</h3>
                    <button *ngIf="mod.HasQuickSummaryBtn" class="summary-btn" [class.open]="mod.SummaryIsShown"
                            (click)="handleModuleSummaryToggle($event, mod)">
                        <icon *ngIf="!mod.SummaryIsShown" name="hamburger"></icon>
                        <icon *ngIf="mod.SummaryIsShown" name="x"></icon>
                    </button>
                </div>
            </button>

            <hc-module-summary *ngIf="mod.SummaryIsShown" [showCloseBtn]="false" [summary]="mod.Summary" @enterLeaveFadeAndSlideAnimation>
            </hc-module-summary>
        </li>
    </ol>
</nav>