import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ModuleSummary } from './hc-module-summary-vm';

@Component({
  selector: 'hc-module-summary',
  templateUrl: './hc-module-summary.component.html',
  styleUrls: ['./hc-module-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcModuleSummaryComponent implements OnInit {
  @HostBinding('class') class = 'hc-styles';
  @Input() showCloseBtn = true;
  @Input() summary: ModuleSummary;

  constructor(
    @Optional() public dialogRef: MatDialogRef<HcModuleSummaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { summary: ModuleSummary }
  ) {}

  ngOnInit(): void {
    if (this.data) this.summary = this.data.summary;
  }

  handleClose() {
    this.dialogRef.close();
  }
}
