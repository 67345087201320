import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { HcModuleService } from '../hc-module-nav/hc-module.service';

@Component({
  selector: 'hc-nav-blocker',
  templateUrl: './hc-nav-blocker.component.html',
  styleUrls: ['./hc-nav-blocker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcNavBlockerComponent implements OnInit {
  constructor(private hcModuleService: HcModuleService) {}

  ngOnInit(): void {}

  handleReturn() {
    this.hcModuleService.setModuleNavState(false);
  }
}
