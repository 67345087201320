import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { enterLeaveFadeAndSlideAnimation } from '../../global/animations';
import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { sortBySortOrder } from '../../utility/helpers/sorters';
import { PageVersion } from '../../utility/interfaces/page';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { BrowserService } from '../../utility/services/browser.service';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { PageService } from '../../utility/services/page.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { HcSessionBuild } from '../hc-session/hc-session-models';
import { HcSessionService } from '../hc-session/hc-session.service';
import { HcModuleNavItemVM } from './hc-module-nav-item-vm';
import { HcModuleSummaryComponent } from './hc-module-summary/hc-module-summary.component';
import { HcModuleService } from './hc-module.service';

@Component({
  selector: 'hc-module-nav',
  templateUrl: './hc-module-nav.component.html',
  styleUrls: ['./hc-module-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAndSlideAnimation(200, 200)] // timing matches summary-btn in css
})
export class HcModuleNavComponent extends AutoUnsubscriber implements OnInit, AfterViewInit {
  @Output() moduleSelect = new EventEmitter<PageTitle>();
  modules: HcModuleNavItemVM[] = [];
  currentFloorplan: EffectiveFloorplan;

  constructor(
    private pageService: PageService,
    public browser: BrowserService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private hcSessionService: HcSessionService,
    private hcModuleService: HcModuleService,
    private navigator: NavigationService,
    private vmFactory: ViewModelFactoryService,
    private elementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit(): void {
    this.modules = this.pageService
      .getAll(PageVersion.HomeConfigurator)
      .filter(x => x.IsActive)
      .sort(sortBySortOrder)
      .map(page => new HcModuleNavItemVM(page));

    this.hcModuleService.startingModuleUniqueName = this.modules[0]?.UniqueName;

    this.addSub(
      this.hcSessionService.currentBuild$.subscribe(this.handleBuildChange),

      this.navigator.current$.subscribe(data => {
        if (!data) return;
        this.modules.forEach(x => {
          x.IsSelected = data.PageTitle === x.UniqueName;
          if (x.IsSelected) x.UserHasVisited = true;
        });
        this.cdr.markForCheck();
      }),

      this.hcModuleService.closeAllSummaries$.subscribe(() =>
        this.modules.forEach(x => (x.SummaryIsShown = false))
      )
    );
  }

  ngAfterViewInit(): void {
    const current = this.modules.find(x => x.IsSelected);
    if (current) {
      const modElement = this.elementRef.nativeElement.querySelector(`#mod-nav-${current.Id}`);
      if (modElement && this.browser.isCompact())
        modElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
    }
  }

  handleBuildChange = (build: HcSessionBuild) => {
    this.modules.forEach(mod => {
      mod.SelectionIsLocked = !!build.InventoryHomeId;

      switch (mod.UniqueName) {
        case PageTitle.FloorPlans:
          let fp: EffectiveFloorplan;
          if (build?.FloorPlan)
            fp = this.vmFactory.getEffectiveFloorplan(
              +build.FloorPlan.EntityId,
              build.InventoryHomeId
            );

          if (fp?.FloorplanId !== this.currentFloorplan?.FloorplanId)
            this.closeModuleSummary(PageTitle.Exterior);

          mod.Summary = this.hcModuleService.getFloorPlanSummary(fp, build.FloorPlan);
          mod.SelectionHasBeenMade = !!fp;
          this.currentFloorplan = fp;
          break;

        case PageTitle.CommunityMap:
          mod.Summary = this.hcModuleService.getLotSummary(
            build?.Lot ? this.vmFactory.getLot(+build.Lot.EntityId) : null,
            build.Lot
          );

          mod.SelectionHasBeenMade = !!build?.Lot;
          break;

        case PageTitle.Interior:
          if (!build?.FloorPlan) {
            mod.IsDisabled = true;
            mod.SelectionHasBeenMade = false;
          } else {
            mod.IsDisabled = false;
          }
          mod.Summary = this.hcModuleService.getInteriorSummary(build?.Interior);
          mod.SelectionHasBeenMade = !!build?.Interior;
          break;

        case PageTitle.Exterior:
          if (!build?.FloorPlan) {
            mod.IsDisabled = true;
            mod.SelectionHasBeenMade = false;
          } else {
            mod.IsDisabled = false;
          }
          mod.Summary = this.hcModuleService.getExteriorSummary(build?.Exterior);
          mod.SelectionHasBeenMade = !!build?.Exterior;
          break;

        case PageTitle.Summary:
          mod.IsDisabled = !build.FloorPlan && !build.Lot;
          mod.SelectionHasBeenMade = !!build.FloorPlan || !!build.Lot;
          break;
      }
    });

    this.cdr.markForCheck();
  };

  handleModuleSelect(mod: HcModuleNavItemVM) {
    mod.UserHasVisited = true;
    const modPageTitle = mod.UniqueName as PageTitle;

    switch (mod.UniqueName) {
      case PageTitle.FloorPlans:
        if (!this.hcSessionService.currentBuild?.FloorPlan)
          this.hcSessionService.showFloorplanListOverlay();
        else {
          this.navigator.go({
            PageTitle: modPageTitle,
            RouteParams: {
              FloorplanId: +this.hcSessionService.currentBuild.FloorPlan.EntityId
            },
            QueryParams: {
              inventoryHomeId: this.hcSessionService.currentBuild.InventoryHomeId
            }
          });
        }
        break;
      default:
        this.navigator.go({ PageTitle: modPageTitle });
        break;
    }
  }

  handleModuleSummaryToggle(evt: Event, mod: HcModuleNavItemVM) {
    evt.stopPropagation();

    if (this.browser.isCompact()) {
      this.dialog.open(HcModuleSummaryComponent, {
        data: { summary: mod.Summary },
        minWidth: '90vw',
        minHeight: '90vh',
        panelClass: 'module-summary-modal'
      });
    } else {
      mod.SummaryIsShown = !mod.SummaryIsShown;
    }
  }

  closeModuleSummary(pageTitle: PageTitle) {
    const mod = this.modules.find(x => x.UniqueName === pageTitle);
    if (mod) mod.SummaryIsShown = false;
  }
}
