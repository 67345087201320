import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import {
  calculateMonthlyPayment,
  convertUnderscoresToBoldHtml,
  formatUsaCurrency
} from '../../utility/helpers/helpers';
import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { HcMortgageCalcTerms, HcSessionBuild } from '../hc-session/hc-session-models';
import { HcSessionService } from '../hc-session/hc-session.service';

@Component({
  selector: 'hc-pricing-outline',
  templateUrl: './hc-pricing-outline.component.html',
  styleUrls: ['./hc-pricing-outline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcPricingOutlineComponent extends AutoUnsubscriber implements OnInit {
  currentFloorplan: EffectiveFloorplan;
  totalPrice: string;
  monthlyPrice: string;
  loanTerms: HcMortgageCalcTerms;
  totalPriceDisclaimer: string;
  mortgageDisclaimer: string;
  showReserveBtn = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private hcSessionService: HcSessionService,
    private vmFactory: ViewModelFactoryService,
    private navigator: NavigationService,
    private settingsService: SettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.totalPriceDisclaimer = this.settingsService
      .get('TotalPriceDisclaimer_HC')
      .replace('{{currentYear}}', new Date().getFullYear());
    this.mortgageDisclaimer = convertUnderscoresToBoldHtml(
      this.settingsService
        .get('MortgageCalculatorDisclaimer_HC')
        ?.replace('{{currentYear}}', new Date().getFullYear())
    );

    this.addSub(
      this.hcSessionService.loanTerms$.subscribe(terms => {
        if (!terms) return;

        this.loanTerms = terms;
        this.monthlyPrice = formatUsaCurrency(
          calculateMonthlyPayment(
            this.hcSessionService.currentBuild.getTotalPrice(),
            this.loanTerms.InterestRate,
            this.loanTerms.Years,
            this.loanTerms.DownPayPercent
          )
        ).replace('$', '');
        this.cdr.markForCheck();
      })
    );

    this.addSub(
      this.hcSessionService.currentBuild$.subscribe(build => {
        this.setFloorPlanAndTotalPrice(build);
        this.cdr.markForCheck();
      })
    );

    this.addSub(
      this.navigator.current$.subscribe(data => {
        this.showReserveBtn = data.PageTitle === PageTitle.Summary;
        this.cdr.markForCheck();
      })
    );
  }

  private setFloorPlanAndTotalPrice(build: HcSessionBuild) {
    if (!build) {
      this.currentFloorplan = null;
      this.totalPrice = '--';
      this.monthlyPrice = '--';
      return;
    }

    if (build.FloorPlan)
      this.currentFloorplan = this.vmFactory.getEffectiveFloorplan(
        +build.FloorPlan.EntityId,
        build.InventoryHomeId
      );

    const totalPrice = this.hcSessionService.currentBuild.getTotalPrice();
    if (totalPrice) {
      this.totalPrice = formatUsaCurrency(totalPrice);
      this.monthlyPrice = formatUsaCurrency(
        calculateMonthlyPayment(
          totalPrice,
          this.loanTerms.InterestRate,
          this.loanTerms.Years,
          this.loanTerms.DownPayPercent
        )
      ).replace('$', '');
    } else {
      this.totalPrice = '--';
      this.monthlyPrice = '--';
    }
  }

  async handleMortgageCalculatorModal() {
    await this.hcSessionService.showMortgageCalculatorOverlay();
  }
}
