import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HcExpandingMenuService {
  private isExpandedSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  get isExpanded$() {
    return this.isExpandedSubject.asObservable();
  }

  toggleIsExpanded() {
    this.isExpandedSubject.next(!this.isExpandedSubject.value);
  }
}
