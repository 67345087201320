<div class="expanding-menu-header">
    <button class="home-btn" (click)="homeSelect.emit()">
        <icon name="home_menu_alt"></icon>
    </button>

    <h4>{{headerText}}</h4>
    <button *ngIf="showBackBtn" class="back-btn" (click)="handleBackButton()">
        <icon name="back_arrow"></icon>
    </button>

    <button class="expand-collapse-btn" (click)="toggleMenuExpandCollapse()">
        <icon *ngIf="(menuService.isExpanded$ | async) === false" name="menu_expand"></icon>
        <icon *ngIf="menuService.isExpanded$ | async" name="menu_collapse"></icon>
    </button>
</div>
<div class="expanding-menu-body">
    <ng-content></ng-content>
</div>

<div *ngIf="canScrollRight" class="scroll-right-shadow"></div>
<div *ngIf="canScrollLeft" class="scroll-left-shadow"></div>