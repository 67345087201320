import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { convertUnderscoresToBoldHtml } from '../../utility/helpers/helpers';
import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { CommunityService } from '../../utility/services/community.service';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { HcModuleService } from '../hc-module-nav/hc-module.service';
import { HcSessionService } from '../hc-session/hc-session.service';

import { ICommunity } from '@ml/common';

@Component({
  selector: 'hc-header',
  templateUrl: './hc-header.component.html',
  styleUrls: ['./hc-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcHeaderComponent extends AutoUnsubscriber implements OnInit {
  community: ICommunity;
  currentFloorplan: EffectiveFloorplan;
  brandImageUrl: string;
  secondaryBrandImageUrl: string;
  brandingHtml: string;
  ihBrandImageUrl: string;

  constructor(
    private communityService: CommunityService,
    private hcSessionService: HcSessionService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService,
    public theme: ThemeService,
    private vmFactory: ViewModelFactoryService,
    private hcModuleService: HcModuleService,
    private navigator: NavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.community = this.communityService.current;

    this.brandImageUrl = this.settingsService.get('HeaderBrandingImage');
    this.secondaryBrandImageUrl = this.settingsService.get('HeaderSecondaryBrandingImage_HC');
    this.brandingHtml = convertUnderscoresToBoldHtml(
      this.settingsService.get('HeaderBrandingText_HC')
    );
    this.ihBrandImageUrl = this.settingsService.get('HeaderInventoryHomeBrandingImage_HC');

    this.addSub(
      this.hcSessionService.currentBuild$.subscribe(build => {
        if (!build?.FloorPlan) this.currentFloorplan = null;
        else
          this.currentFloorplan = this.vmFactory.getEffectiveFloorplan(
            +build.FloorPlan.EntityId,
            build.InventoryHomeId,
            { useStore: true }
          );

        this.cdr.markForCheck();
      })
    );
  }

  handleFloorPlanSelect() {
    this.hcSessionService.showFloorplanListOverlay({ fpToLimitTo: this.currentFloorplan });
  }

  handleOpenSavedBuilds() {
    this.hcSessionService.showSavedBuildsOverlay();
  }

  async clearSessionAndGoBackToStart() {
    const clear = await this.hcSessionService.confirmClearSessionData();

    if (clear && this.hcModuleService.startingModuleUniqueName) {
      if (this.hcModuleService.startingModuleUniqueName === PageTitle.FloorPlans) {
        this.hcSessionService.showFloorplanListOverlay();
      }
      this.navigator.go({ PageTitle: this.hcModuleService.startingModuleUniqueName });
    }
  }
}
