<fullscreen-gallery></fullscreen-gallery>
<toast></toast>

<div class="phone-landscape-blocker">
    <icon name="rotate_device"></icon>
    <p>Please rotate your device to use this application</p>
</div>

<div class="grid-container hc-styles">
    <hc-header></hc-header>
    <hc-module-nav *ngIf="browser.isDesktopSized$ | async"></hc-module-nav>
    <hc-pricing-outline></hc-pricing-outline>
    <div class="main-content">
        <router-outlet></router-outlet>

        <ng-container *ngIf="(browser.isDesktopSized$ | async) === false && (hcModuleService.moduleNavState$ | async)">
            <hc-nav-blocker></hc-nav-blocker>

            <hc-expanding-menu class="home-alt">
                <hc-module-nav></hc-module-nav>
            </hc-expanding-menu>
        </ng-container>
    </div>
</div>