import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { PushToGoogleTagManager } from '../utility/helpers/google-tag-manager';
import { AutoUnsubscriber } from '../utility/helpers/mixins';
import { BrowserService } from '../utility/services/browser.service';
import { CommunityService } from '../utility/services/community.service';
import { FavoritesService } from '../utility/services/favorites.service';
import { HomeBuyerService } from '../utility/services/home-buyer/home-buyer.service';
import { NavigationService } from '../utility/services/navigation.service';
import { HcModuleService } from './hc-module-nav/hc-module.service';

import { IHomeBuyer } from '@ml/common';

@Component({
  selector: 'home-configurator-root',
  templateUrl: './home-configurator-root.component.html',
  styleUrls: ['./home-configurator-root.component.scss', './hc-grid-layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeConfiguratorRootComponent extends AutoUnsubscriber implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  constructor(
    private favorites: FavoritesService,
    private homeBuyerService: HomeBuyerService,
    private communityService: CommunityService,
    private navigator: NavigationService,
    public browser: BrowserService,
    public hcModuleService: HcModuleService
  ) {
    super();
  }

  ngOnInit() {
    const community = this.communityService.current;
    this.navigator.detectApplicationMode();

    this.favorites.turnOffFavoriting();

    this.updateBrowserTabTitle(this.communityService.getDisplayName());

    try {
      this.homeBuyerService.initializeFromLocalStorage(community);

      this.subs.push(
        this.homeBuyerService.current$.subscribe((homeBuyer: IHomeBuyer) => {
          if (homeBuyer) {
            console.log(
              'TODO: Implement home buyer session tracking for Home Configurator',
              homeBuyer
            );
          }
        })
      );
    } catch {
      console.error('HomeBuyer email found in sessionStorage but unable to retrieve');
    }

    this.addSub(
      this.navigator.current$.subscribe(() => {
        if (this.browser.isCompact()) this.hcModuleService.setModuleNavState(false);
      })
    );

    PushToGoogleTagManager({
      eventName: 'CommunityLoad',
      eventValue: community.CommunityId.toString(),
      extraData: {
        CommunityId: community.CommunityId,
        CommunityName: community.Name,
        DivisionId: community.ProjectId,
        DivisionName: community.ProjectName,
        ClientId: community.ClientId,
        ClientName: community.ClientName
      }
    });
  }

  ngOnDestroy() {
    this.subs.forEach(x => x.unsubscribe());
  }

  private updateBrowserTabTitle(communityName: string) {
    const title = document.querySelector('head title');
    if (title) title.innerHTML = `Home Configurator - ${communityName}`;
  }
}
