<button *ngIf="showCloseBtn" class="close-btn" (click)="handleClose()">
    <icon name="x"></icon>
</button>

<div class="summary-section" *ngFor="let section of summary.Sections">
    <table>
        <thead>
            <th *ngFor="let colName of section.ColumnNames">{{colName}}</th>
        </thead>
        <tbody>
            <tr *ngFor="let item of section.Items">
                <td>
                    <h2>{{item.Name}}</h2>
                    <h4>{{item.Subcontent}}</h4>
                </td>
                <td>
                    <h2>{{item.Value}}</h2>
                </td>
            </tr>
            <tr *ngIf="!section.Items.length">
                <td>---</td>
                <td>---</td>
            </tr>
        </tbody>
    </table>
</div>

<button *ngIf="summary.Action" class="hc-btn primary" (click)="summary.Action()">
    <span *ngIf="summary.HasSelections">View &amp; Change</span>
    <span *ngIf="!summary.HasSelections">Select {{summary.EntityLabel}}</span>
</button>