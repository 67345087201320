import { IBaseContentJson } from '../../utility/interfaces/page';
import { PageVM } from '../../utility/models/page-vm';
import { PageTitle } from '../../utility/services/navigation.service';
import { ModuleSummary } from './hc-module-summary/hc-module-summary-vm';

export class HcModuleNavItemVM {
  Id: number;
  Title: string;
  UniqueName: PageTitle;
  Subtitle: string;
  UserHasVisited = false;
  SelectionIsRequired = false;
  SelectionHasBeenMade = false;
  SelectionIsLocked = false;
  IsSelected = false;
  HasQuickSummaryBtn = true;
  SummaryIsShown = false;
  Summary: ModuleSummary;
  IsDisabled = false;

  constructor(data?: PageVM<IBaseContentJson>) {
    if (data) {
      this.Id = data.PageId;
      this.Title = data.NavigationTitle || data.PageTitle;
      this.UniqueName = data.PageTitle;
      this.Subtitle = data.PageHeading;
      this.HasQuickSummaryBtn = data.PageTitle !== PageTitle.Summary;

      this.SelectionIsRequired =
        data.PageTitle === PageTitle.FloorPlans || data.PageTitle === PageTitle.CommunityMap;
    }
  }
}
