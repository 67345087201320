<div class="block-one">
    <div class="app-title">
        <img *ngIf="brandImageUrl" [src]="brandImageUrl" alt="Brand Image" />

        <ng-container *ngIf="!currentFloorplan || !currentFloorplan.IsInventoryHome">
            <img *ngIf="secondaryBrandImageUrl" [src]="secondaryBrandImageUrl" class="secondary" alt="Brand Image" />
            <span *ngIf="!secondaryBrandImageUrl && brandingHtml" [innerHtml]="brandingHtml"></span>
        </ng-container>

        <ng-container *ngIf="currentFloorplan?.IsInventoryHome">
            <img *ngIf="ihBrandImageUrl" [src]="ihBrandImageUrl" class="secondary" alt="IH Brand Image" />
            <span *ngIf="!ihBrandImageUrl">Your <b class="inventory-home">{{theme.inventoryHomeLabel}}</b></span>
        </ng-container>
    </div>
    <button class="hc-btn primary clear-session-btn" (click)="clearSessionAndGoBackToStart()" aria-label="Clear Session">
        <ml-icon name="reset_arrow"></ml-icon>
        <span class="label">Clear Session</span>
    </button>
    <button class="hc-btn primary saved-builds-btn" (click)="handleOpenSavedBuilds()" aria-label="Saved Builds">
        <ml-icon name="user"></ml-icon>
        <span class="label">Saved Items</span>
    </button>

    <button class="close-btn">
        <ml-icon name="x"></ml-icon>
    </button>
</div>

<div class="block-two">
    <div class="fp-title-container">
        <ml-icon name="floorplan"></ml-icon>

        <div class="text-container">
            <p class="subtitle">
                Selected {{theme.fpLabel}}
            </p>
            <h3 class="fp-name">
                <div class="bubble" [class.warning]="!currentFloorplan">
                    <ml-icon *ngIf="currentFloorplan" name="check"></ml-icon>
                    <ml-icon *ngIf="!currentFloorplan" name="exclamation"></ml-icon>
                </div>
                <button class="hc-btn underline" (click)="handleFloorPlanSelect()">
                    <span *ngIf="currentFloorplan">{{currentFloorplan.Name}}</span>
                    <span *ngIf="!currentFloorplan">Select a {{theme.fpLabel}}</span>
                </button>
            </h3>
        </div>
    </div>
    <button class="hc-btn primary clear-session-btn" (click)="clearSessionAndGoBackToStart()" aria-label="Clear Session">
        <ml-icon name="reset_arrow"></ml-icon>
        <span class="label">Clear Session</span>
    </button>
    <button class="hc-btn primary saved-builds-btn" (click)="handleOpenSavedBuilds()" aria-label="Saved Builds">
        <ml-icon name="user"></ml-icon>
        <span class="label">Saved Items</span>
    </button>
</div>